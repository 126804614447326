<script setup lang="ts">
import { useActiveUser, ActiveUser } from '~/utilities/data/activeUser';
import { DialogWrapper } from 'vue3-promise-dialog';

const activeUser = useActiveUser();
await activeUser.load({ silentOnError: true });
let user: globalThis.ComputedRef<ActiveUser>;

if (activeUser.isLoggedIn) {
    user = storeToRefs(activeUser).user;
}

const environmentName = useRuntimeConfig().public.environmentName;
const environmentColor = useRuntimeConfig().public.environmentColor;
let faviconName = useRuntimeConfig().public.faviconName;
let favIcon = await import(`~/public/${faviconName}.png`);

const fullscreen = useFullscreen();

const toggleFullscreen = () => {
    if (fullscreen.isSupported) {
        fullscreen.toggle();
    }
}

</script>

<template>
    <DialogWrapper />

    <VApp :theme="activeUser.getActiveTheme()">
        <VAppBar>
            <template #prepend>
                <VAvatar @click="toggleFullscreen" class="mr-2" style="cursor: pointer;" :image="favIcon.default" />
                <div v-if="user">
                    <div class="text-body-1">{{ user.username }}</div>
                    <div class="text-caption text-grey">{{ user.email }}</div>
                </div>
                <div v-else>
                    <VIcon class="mr-1" color="warning" icon="mdi-alert-circle-outline"></VIcon>
                    <span class="text-body-1 text-warning">{{ 'No user is logged in!' }}</span>
                </div>
                <h2 :style="`color: ${environmentColor} `">{{ environmentName }}</h2>
            </template>
            <template #append>
                <UserMenu logoutMode="mobile" :hideListItems="['setting', 'profile']"></UserMenu>
            </template>
        </VAppBar>
        <VMain>
            <LayoutNotification></LayoutNotification>
            <VContainer class="pa-4 mb-0">
                <slot></slot>
            </VContainer>
        </VMain>
    </VApp>
</template>

<style lang="scss">
@import "../assets/styles/options";
</style>